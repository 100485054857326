<script>
import {mapGetters} from "vuex";
import {namespaces} from "@/store/namespaces";

export default {
    name: "DoctorMessage",
    computed: {
        ...mapGetters({
            getSelectedDoctor: namespaces.calendar + 'getSelectedDoctor',
            getDoctors: namespaces.calendar + 'getDoctors',
        }),
        doctor() {
            return this.getDoctors.find(d=> d.id === this.getSelectedDoctor.id)
        }
    },
}
</script>

<template>
    <div v-if="getSelectedDoctor">
        <!-- tous les médecins -->
        <transition name="fade" mode="out-in">
            <div v-if="getSelectedDoctor.id === 0" class="row">
                <template v-for="doctor in getDoctors">
                    <div v-if="doctor.message" class="col-lg-3">
                        <v-menu>
                            <div class="clickable">
                                <div class="card border-info">
                                    <div class="card-body d-flex align-items-center p-2">
                                        <div class="h2 m-0 text-info mr-3">
                                            <i class="fa fa-info-circle"></i>
                                        </div>
                                        <div>
                                            {{doctor.label}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <template #popper>
                                <div class="p-4 shadow doctor-message" v-html="doctor.message">
                                </div>
                            </template>
                        </v-menu>
                    </div>
                </template>
            </div>
            <div v-else class="row">
                <div v-if="doctor && doctor.message" class="col-md-12">
                    <div class="card border-info">
                        <div class="card-body">
                            <div v-html="doctor.message"></div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<style scoped lang="scss">
.doctor-message {
    min-width: 250px;
    max-height: 35vh;
}
</style>