<template>
    <div id="modal-read-only" class="modal fade" tabindex="-1" aria-labelledby="modal-read-only" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    Attention
                </div>
                <div class="modal-body">
                    <div v-html="message"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "ModalReadOnly",
    data() {
        return {
            message: Vue.$config.etablissement.read_only_msg
        }
    },

}
</script>

<style scoped>

</style>