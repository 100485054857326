<template>
    <div id="calendar-view" class="mb-5">
        <div class="main-title">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h3><i class="bi bi-calendar-week-fill mr-3"></i>{{$t('global.titre_page_prise_rdv')}}</h3>
                        <div class="underline"></div>
                    </div>
                </div>
            </div>
        </div>
        <calendar></calendar>
    </div>
</template>

<script>
    import Calendar from "@/components/front/calendar/Calendar.vue";
    export default {
        name: 'CalendarView',
        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Calendrier',
        },
        components: {
            Calendar
        },

    }
</script>
