<template>
    <div class="modal fade" ref="confirmationModal" id="confirmationModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header" :class="appointment ? 'table-success' : ''">
                    <transition name="fade" mode="in-out">
                        <h5 v-if="!appointment" class="modal-title">Confirmation de rendez-vous</h5>
                        <h5 v-else class="modal-title"><i class="bi bi-check mr-3"></i>Rendez vous enregistré avec succès !</h5>
                    </transition>
                    <button @click="onDismiss()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div v-if="!getIsLoggedIn" class="alert alert-info">
                        <div class="d-flex align-items-center">
                            <div class="p-3">
                                <h3><i class="bi bi-info-circle-fill"></i></h3>
                            </div>
                            <div>
                                Vous devez être connecté pour confirmer votre rendez-vous.
                            </div>
                        </div>
                        <div class="text-right mt-3">
                            <a href="#" @click="onConnexion"><i class="bi bi-box-arrow-in-right mr-3"></i>Connexion</a><br>
                            <a href="#" @click="onInscription"><i class="bi bi-chevron-double-right mr-3"></i>Pas encore de compte?</a>
                        </div>
                    </div>
                    <div v-else-if="!appointment">
                        <div class="alert alert-info" v-html="$t('global.info-prise-de-rdv-individuelle')">

                        </div>
                        <div v-if="getHasDoubleCalendarMsg" class="alert alert-warning">
                            <div v-html="getHasDoubleCalendarMsg"></div>
                        </div>
                    </div>
                    <div id="recap">
                        <div v-if="getSelectedSlot">
                            <div class="alert alert-primary mb-3" v-if="!getSelectedSlot.extendedProps.doctor.accept_new_patients && getSelectedSlot.extendedProps.doctor.message_new_patients">
                                <div v-html="getSelectedSlot.extendedProps.doctor.message_new_patients"></div>
                            </div>
                            <table class="border-0">
                                <tr>
                                    <td class="pr-4"> {{ $t('global.docteur') }} : </td>
                                    <td>{{getSelectedSlot.extendedProps.doctor.name + ' ' + getSelectedSlot.extendedProps.doctor.firstname}}</td>
                                </tr>
                                <tr>
                                    <td class="pr-4">Lieu : </td>
                                    <td>{{getSelectedSlot.extendedProps.office.name }}</td>
                                </tr>
                                <tr>
                                    <td class="pr-4">Motif : </td>
                                    <td>{{getSelectedSlot.extendedProps.reason.label }}</td>
                                </tr>
                                <tr>
                                    <td class="pr-4">Date de début : </td>
                                    <td>{{moment(getSelectedSlot.start).format('DD-MM-YYYY [à] HH[h]mm')}}</td>
                                </tr>
                                <tr>
                                    <td class="pr-4">Date de fin : </td>
                                    <td>{{moment(getSelectedSlot.end).format('DD-MM-YYYY [à] HH[h]mm')}}</td>
                                </tr>
                            </table>
                        </div>
                        <div v-if="config.etablissement.patient_comments" class="mt-3">
                            <label for="comment">Commentaire :</label>
                            <textarea v-if="!appointment" name="comment" id="comment" cols="30" rows="2" class="w-100 form-control" v-model="comment" maxlength="120" :disabled="appointment"></textarea>
                            <div v-else>{{comment}}</div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="text-danger w-100 text-right">
                        <i>Veuillez vérifier les informations de votre rendez-vous avant confirmation ({{$t('global.docteur')}}, lieu, etc.).</i>
                    </div>
                    <button class="btn btn-sm btn-primary" v-if="appointment && config.etablissement.display_print_button" @click="onPrint(appointment.id)">
                        <span v-if="printing" class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
                        <i v-else class="bi bi-printer-fill mr-3" ></i>
                        Imprimer</button>
                    <button id="close-confirmation-modal" ref="closeConfirmationModal" type="button" class="btn btn-sm btn-secondary" data-dismiss="modal" @click="onDismiss()">{{appointment ? 'Fermer' : 'Annuler'}}</button>
                    <button v-if="!appointment" type="button" class="btn btn-sm btn-primary" @click="onConfirmation" :disabled="!getUser || !getUser.matricule">
                        <span v-if="loading" class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
                        <span v-if="loading" >Chargement...</span>
                        <span v-if="!loading"><i class="bi bi-check mr-3"></i>Confirmer
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import {namespaces} from "../../../../store/namespaces";
    import calendarApi from '../../../../api/calendar'
    import { EventBus, EventList } from '../../../../event-bus.js';
    import moment from 'moment'
    import axios from 'axios'

    export default {
        name: "ModalConfirmation",
        data() {
            return {
                loading: false,
                pending: 0,
                existing_patient: false,
                existing_db: false,
                comment: null,
                moment: moment,
                appointment: null,
                config: Vue.$config,
                printing: false
            }
        },
        computed: {
            ...mapGetters({
                getIsLoggedIn: namespaces.auth + 'getIsLoggedIn',
                getUser: namespaces.auth + 'getUser',
                getApiToken: namespaces.auth + 'getApiToken',
                getSelectedReason: namespaces.calendar+'getSelectedReason',
                getSelectedDoctor: namespaces.calendar+'getSelectedDoctor',
                getSelectedOffice: namespaces.calendar+'getSelectedOffice',
                getSelectedSlot: namespaces.calendar+'getSelectedSlot',
                getOffices: namespaces.calendar+'getOffices',
                getDoctors: namespaces.calendar+'getDoctors',
                getReasons: namespaces.calendar+'getReasons',
                getHasDoubleCalendarMsg: namespaces.calendar+'getHasDoubleCalendarMsg'
            }),
        },
        methods: {
            onDismiss() {
                this.loading = false
                setTimeout(()=> {
                    this.appointment = null
                }, 500)
            },
            onInscription() {
                this.$refs['closeConfirmationModal'].click()
                setTimeout(()=> {
                    this.$router.push('/inscription')
                }, 500);
            },
            async onConfirmation() {
                try {
                    this.loading = true
                    let resDoctorUser = await calendarApi.getDoctorUser(this.getSelectedSlot.extendedProps.doctor_id, this.getUser.id)

                    if(resDoctorUser.data.doctor_user.length) {
                        this.existing_db = true

                        if(resDoctorUser.data.doctor_user[0].existing_patient === 1) {
                            this.existing_patient = true
                        }
                    }

                    if(!this.existing_patient && !this.existing_db) {
                        let exist = 0

                        Vue.$config.etablissement.waiting_list ? exist = 0 : exist = 1
                        let res = await calendarApi.storeDoctorUser(this.getSelectedSlot.extendedProps.doctor_id, this.getUser.id, exist)
                    }

                    !this.getSelectedSlot.extendedProps.doctor.accept_new_patients && !this.existing_patient
                        ? this.pending = 1 : this.pending = 0

                    let res = await calendarApi.store({
                        doctor_id: this.getSelectedSlot.extendedProps.doctor_id,
                        reason_id: this.getSelectedSlot.extendedProps.reason.id,
                        office_id: this.getSelectedSlot.extendedProps.office.id,
                        user_id: this.getUser.id,
                        date_debut: moment(this.getSelectedSlot.start).format('YYYY-MM-DD HH:mm:ss'),
                        date_fin: moment(this.getSelectedSlot.end).format('YYYY-MM-DD HH:mm:ss'),
                        comment: this.comment,
                        app_id: Vue.$config.app_id,
                        pending: this.pending
                    })

                    this.appointment = res.data.appointment
                    EventBus.$emit(EventList.calendar_front.refresh);

                    Vue.$toast.open({
                        message: 'Rendez-vous enregistré avec succès!',
                        type: 'success',
                    });

                    $('#confirmationModal').modal('hide')
                    this.loading = false
                }catch (e) {
                    Vue.$toast.open({
                        message: 'Une erreur s\'est produite',
                        type: 'error',
                    });
                }
            },
            onConnexion() {
                $('#confirmationModal').modal('hide')
                $('#connexionModal').modal('show')
            },
            async onPrint(id) {
                this.printing = true
                let res = await axios({
                    url: '/appointment/print/'+id,
                    method: 'GET',
                    responseType: 'blob',
                    headers: {
                        "content-type": "application/json",
                        "Accept": "application/json, text/plain",
                        "Authorization": "Bearer " + this.getApiToken
                    }
                })
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'rendez-vous-'+moment(this.getSelectedSlot.start).format('DD-MM-YY_HH-mm')+'.pdf');
                document.body.appendChild(link);
                link.click();
                this.printing = false
            },
        }
    }
</script>

<style scoped>

</style>
